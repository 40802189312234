<template>
  <div
    class="meetingRoomDlog"
    v-customDrag
    v-if="$store.state.multimedia_meetingmode.multimediaSelectMeetingDialog"
    :class="
      $store.state.multimedia_meetingmode.meetingWindowStyle === 'largestStyle'
        ? 'largestStyle'
        : ''
    "
    ref="meetingModel"
    :style="{
      top: windowTopCoordinate,
      left: windowLeftCoordinate,
      width: windowWidth,
      height: windowHeight,
    }"
  >
    <div class="cover"></div>
    <div class="dragOperationArea">
      <div
        class="slectbox"
        :style="{
          padding: meetingWindowStyle === 'smallStyle' ? '80px 40px' : '',
        }"
      >
        <el-input
          type="textarea"
          autosize
          placeholder=""
          v-model="textarea1"
          resize="none"
        >
        </el-input>
        <div class="line"></div>
        <a class="address">{{ meetingAddress }}</a>
        <div class="opration">
          <div class="copy" @click="copy">{{ $t("copy") }}</div>
          <div class="copy-join" @click="copyJoin">{{ $t("copyAndJoin") }}</div>
        </div>

        <div class="close" @click="dialogClose">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div
        class="miniShow"
        @mouseup="gomouseup"
        @mousedown="gomousedown"
        @mousemove="gomousemove"
        v-show="
          $store.state.multimedia_meetingmode.meetingWindowStyle ===
            'smallStyle'
        "
      >
        <div class="timingText">
          {{ meetingTimingText }}
        </div>
        <div class="meetingIcon">
          <i class="iconfont_Me icon-video-fill iconStyle"></i>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import * as RESTApi from "@/api/rest";
import { hangUpTheCall } from "../multimedia_meetingmode/coreOperations/local";
export default {
  components: {},
  data() {
    return {
      // 窗口初始化的Y坐标位置
      positionTop: 0,
      // 窗口初始化的X坐标位置
      positionLeft: 0,
      // 窗口展示宽度状态（smallScreen: 小屏幕窗口状态；largeScreen：大屏幕窗口状态）
      screenSizeForMeeting: "largeScreen",
      // 用于通知子组件音视频SDK准备完毕
      sdkReady: false,
      // 最小化鼠标按下标记
      mousedownFlag: false,
      // 点击拖动判断
      timmerHandle: null,
      textarea1: this.$t("meetingAddress") + " : ",
      meetingAddress: "",
      dialogVisible: false,
    };
  },
  computed: {
    // 会议计时
    meetingTimingText() {
      return this.$store.state.multimedia_meetingmode.meetingTimingText;
    },
    // 当前窗口的展示类型
    meetingWindowStyle() {
      return this.$store.state.multimedia_meetingmode.meetingWindowStyle;
    },
    // 窗口当前真正显示的宽度
    windowWidth() {
      let windowWidth = `${this.normalWidth}px`;
      if (this.meetingWindowStyle === "smallStyle") {
        windowWidth = "224px";
      } else if (this.meetingWindowStyle === "largestStyle") {
        windowWidth = "100%";
      }
      return windowWidth;
    },
    // 窗口当前真正显示的高度
    windowHeight() {
      let windowHeight = `${this.normalHeight}px`;
      if (this.meetingWindowStyle === "smallStyle") {
        windowHeight = "518px";
      } else if (this.meetingWindowStyle === "largestStyle") {
        windowHeight = "100%";
      }
      return windowHeight;
    },
    // 窗口当前相对于屏幕顶部的距离
    windowTopCoordinate() {
      let windowTopCoordinate = "auto";
      // 如果当前窗口为mini悬窗的时候
      if (this.meetingWindowStyle === "smallStyle") {
        // windowTopCoordinate = "144px";
        windowTopCoordinate = `${this.positionTop}px`;
      } else if (this.meetingWindowStyle === "normalStyle") {
        windowTopCoordinate = `${this.positionTop}px`;
      }
      return windowTopCoordinate;
    },
    // 窗口当前相对于屏幕左侧的距离
    windowLeftCoordinate() {
      let windowLeftCoordinate = "auto";
      // 如果当前窗口为mini悬窗的时候
      if (this.meetingWindowStyle === "smallStyle") {
        windowLeftCoordinate = `${document.documentElement.clientWidth -
          122 -
          125}px`;
        // windowLeftCoordinate = `${this.positionLeft}px`;
      } else if (this.meetingWindowStyle === "normalStyle") {
        windowLeftCoordinate = `${this.positionLeft}px`;
      }
      return windowLeftCoordinate;
    },
    // 正常窗口根据屏幕大小应该显示的宽度
    normalWidth() {
      let normalWidth = 728;
      if (this.screenSizeForMeeting === "smallScreen") {
        normalWidth = 397;
      }
      return normalWidth;
    },
    // 正常窗口根据屏幕大小应该显示的高度
    normalHeight() {
      let normalHeight = 518;
      normalHeight = 650;
      if (this.screenSizeForMeeting === "smallScreen") {
        normalHeight = document.documentElement.clientWidth <= 1400 ? 590 : 650;
      }
      return normalHeight;
    },
  },
  mounted() {
    // 根据屏幕宽度设置会议窗口宽度
    window.addEventListener(
      "resize",
      async () => {
        await this.initWindowPosition();
      },
      false
    );
    this.initWindowPosition();
    this.shareMeetingLink();
  },
  beforeDestroy() {
    window.removeEventListener(
      "resize",
      async () => {
        await this.initWindowPosition();
      },
      false
    );
  },
  methods: {
    handleClose() {
      console.log("关闭弹窗");
    },
    // 最小化窗口时，判断是拖动还是点击事件
    gomousedown() {
      this.mousedownFlag = true;
    },
    gomousemove() {
      // 如果鼠标已经按下，才开始触发拖动事件
      if (this.mousedownFlag) {
        if (this.timmerHandle == null) {
          this.timmerHandle = setTimeout(() => {
            this.mousedownFlag = false;
          }, 200);
        }
      }
    },
    gomouseup() {
      clearTimeout(this.timmerHandle);
      this.timmerHandle = null;
      if (this.mousedownFlag) {
        this.mousedownFlag = false;
        this.setNormalWindow();
      }
    },
    // 初始化窗口坐标位置
    async initWindowPosition() {
      let screenWidth = document.documentElement.clientWidth;
      let screenHeight = document.documentElement.clientHeight;
      if (screenWidth <= 1400) {
        this.positionTop = screenHeight - 590;
      } else {
        this.positionTop = screenHeight - 650;
      }
      // 根据屏幕宽度设置窗口大小
      await this.initWindowSize(screenWidth);
      let windowWidth = 0;
      if (this.meetingWindowStyle === "largestStyle") {
        windowWidth = screenWidth;
      } else {
        windowWidth = this.windowWidth.substr(0, this.windowWidth.length - 2);
      }
      let remainingWidthOnLeft = screenWidth - 960 - windowWidth - 36;

      if (remainingWidthOnLeft > 0) {
        this.positionLeft = remainingWidthOnLeft;
      } else {
        this.positionLeft = 0;
      }
    },
    // 将窗口重置为普通窗口
    setNormalWindow() {
      this.$store.commit("setMeetingWindowStyle", "normalStyle");
    },
    // 根据屏幕大小设置窗口宽高度
    initWindowSize(screenWidth) {
      return new Promise((resolve, reject) => {
        // 获取屏幕剩余宽度
        let remainingWidth = screenWidth - 960;
        if (remainingWidth < 728) {
          // this.screenSizeForMeeting = "largeScreen";
          this.screenSizeForMeeting = "smallScreen";
        } else {
          this.screenSizeForMeeting = "largeScreen";
        }
        this.$forceUpdate();
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    //关闭弹窗
    dialogClose() {
      this.$store.commit("setMultimediaSelectMeetingDialog", false);
      this.textarea1 = this.$t("meetingAddress") + " : ";
    },
    // 分享会议链接
    async shareMeetingLink() {
      let params = {
        groupId: this.$store.state.multimedia_meetingmode.meetingGroupId,
      };
      let result = await RESTApi.createShareAddress(params);
      if (result.code == 200) {
        this.meetingAddress = result.data.data.meetingAddress;
        let a = this.meetingAddress.match(/\d+(.\d+)?/g);
        if (a[0]) {
          this.$store.commit("setMeetingRoomId", a[0]);
        }
      } else {
        // this.$message({
        //   type: "error",
        //   message: result.message,
        // });
      }
    },
    //复制链接
    copy() {
      if (this.meetingAddress == "") {
        return;
      }
      this.$copyText(`${this.textarea1}${this.meetingAddress}`).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
          this.dialogClose();
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
          this.dialogClose();
        }
      );
    },
    //复制并进入会议
    async copyJoin() {
      if (this.meetingAddress == "") {
        return;
      }
      this.$store.commit("setClickTheBrowserBackButton", false);
      let meetingGroupId = this.$store.state.multimedia_meetingmode
        .meetingGroupId;
      let roomId = this.meetingAddress.match(/\d+/g)[0];
      this.copy();
      this.$store.commit("setOldMeetingHref", this.meetingAddress);
      if (this.$store.state.multimedia_meetingmode.meetingWindowStatus) {
        this.$confirm(this.$t("joinNewMeetTips"), " ", {
          customClass: "joinTips",
          confirmButtonText: " ",
          cancelButtonText: " ",
          center: true,
          cancelButtonClass: "el-icon-close my-confirm-btn",
          confirmButtonClass: "el-icon-check my-confirm-btn",
          showCancelButton: true,
          type: "warning",
        })
          .then(async () => {
            this.$store.commit("setMeetingChannelName", meetingGroupId);
            this.$store.commit("setMeetingGroupId", meetingGroupId);
            setTimeout(async () => {
              await this.hangupTheCall();
              // this.$store.commit("setMeetingWindowStatus", true);
              // window.open(testMeetingAddress, "_self");
              this.$router.push({
                path: `/meeting/${roomId}`,
              });
            }, 0);
            return;
          })
          .catch(() => {});
      } else {
        // 如何在其他端登录，弹出选择框(更换设备/同时加入<生成一个新的子账号>)
        if (false) {
          this.$store.commit("setMeetingModeSelectPopover", true);
        } else {
          // await this.hangupTheCall();
          this.$router.push({
            path: `/meeting/${roomId}`,
          });
        }

        // window.open(testMeetingAddress, "_self");
      }
    },
    // 点击挂断按钮，中断通讯
    async hangupTheCall() {
      if (!this.$store.state.multimedia_meetingmode.localStreamIsReady) {
        console.log("本地流未准备好,将强制挂断");
      }
      // 先通知后台再挂断网易云信
      let params = {
        chatType: "groupChat",
        subjectId: this.$store.state.multimedia_meetingmode.oldMeetingGroupId,
      };
      let result = await RESTApi.exitMeeting(params);
      if (result.code == 200) {
        hangUpTheCall()
          .then((e) => {
            // 处理完数据后，将窗口关闭
            this.$store.commit("setMeetingWindowStatus", false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.meetingRoomDlog {
  position: fixed;
  overflow: hidden;
  user-select: none;
  // box-shadow: 0 0 25px rgba(0,0,0,0.2);
  background: url('../../../assets/images/im/immantle.jpg') no-repeat 100% 100%;
  background-size: cover;
  border-radius: 6px;
  color: #fff;
  z-index: 2001;
  min-width:320px!important;

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(40, 40, 40, 0.7);
  }

  .dragOperationArea {
    width: 100%;
    height: 100%;

    .testshow {
      width: 100%;
      height: 100%;
    }

    .slectbox {
      width: 100%;
      height: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 165px 100px;
      display: flex;
      flex-direction: column;
      .address{
        word-break: break-word;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 20px;
        cursor: pointer;

        .icon-close {
          font-size: 20px;
        }
      }

      :deep .el-textarea__inner {
        background: transparent;
        border: none;
        color: #fff;
        padding: 0;
        font-size: 16px;
        // height 24px!important;
        // min-height: 24px!important;
        max-height: 160px !important;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        opacity: 0.2;
        border: 1px solid #ffffff;
        margin: 16px 0;
      }

      .opration {
        margin-top: auto;
        display: flex;

        .copy {
          width: 162px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #33cc66;
          border-radius: 4px;
          color: #52BD68;
          cursor: pointer;
          margin-right: 46px;

          &:hover {
            background: #4eb16f;
            color: #fff;
            // border-color: #fff;
          }
        }

        .copy-join {
          width: 209px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #33cc66;
          cursor: pointer;
          border-radius: 4px;

          &:hover {
            background: #4eb16f;
          }
        }
      }
    }

    .miniShow {
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: #52BD68;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .timingText {
        color: #FFFFFF;
        font-size: 12px;
        width: 100%;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
      }

      .meetingIcon {
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;

        .iconStyle {
          font-size: 45px;
          color: #FFFFFF;
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
    .meetingRoomDlog{
      // background: red;
      background-size: cover;
      .dragOperationArea {
        .slectbox {
          padding:80px 40px;
          .address{
            word-break: break-word;
          }
        }
      }
    }
}
</style>
