var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.multimedia_meetingmode.multimediaSelectMeetingDialog
    ? _c(
        "div",
        {
          directives: [{ name: "customDrag", rawName: "v-customDrag" }],
          ref: "meetingModel",
          staticClass: "meetingRoomDlog",
          class:
            _vm.$store.state.multimedia_meetingmode.meetingWindowStyle ===
            "largestStyle"
              ? "largestStyle"
              : "",
          style: {
            top: _vm.windowTopCoordinate,
            left: _vm.windowLeftCoordinate,
            width: _vm.windowWidth,
            height: _vm.windowHeight
          }
        },
        [
          _c("div", { staticClass: "cover" }),
          _c("div", { staticClass: "dragOperationArea" }, [
            _c(
              "div",
              {
                staticClass: "slectbox",
                style: {
                  padding:
                    _vm.meetingWindowStyle === "smallStyle" ? "80px 40px" : ""
                }
              },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: "",
                    placeholder: "",
                    resize: "none"
                  },
                  model: {
                    value: _vm.textarea1,
                    callback: function($$v) {
                      _vm.textarea1 = $$v
                    },
                    expression: "textarea1"
                  }
                }),
                _c("div", { staticClass: "line" }),
                _c("a", { staticClass: "address" }, [
                  _vm._v(_vm._s(_vm.meetingAddress))
                ]),
                _c("div", { staticClass: "opration" }, [
                  _c("div", { staticClass: "copy", on: { click: _vm.copy } }, [
                    _vm._v(_vm._s(_vm.$t("copy")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "copy-join", on: { click: _vm.copyJoin } },
                    [_vm._v(_vm._s(_vm.$t("copyAndJoin")))]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "close", on: { click: _vm.dialogClose } },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.multimedia_meetingmode
                        .meetingWindowStyle === "smallStyle",
                    expression:
                      "\n        $store.state.multimedia_meetingmode.meetingWindowStyle ===\n          'smallStyle'\n      "
                  }
                ],
                staticClass: "miniShow",
                on: {
                  mouseup: _vm.gomouseup,
                  mousedown: _vm.gomousedown,
                  mousemove: _vm.gomousemove
                }
              },
              [
                _c("div", { staticClass: "timingText" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.meetingTimingText) + "\n      "
                  )
                ]),
                _vm._m(0)
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "meetingIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon-video-fill iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }